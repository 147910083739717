import React from "react";
import Button from "../form/Button";
import { TableCell, TableHead, TableRow } from "../Table";
import { useUserContext } from "../../contexts/UserContext";
import { ReturnLabelButtons } from "./ReturnLabelButtons";

export const IrrepairableView = (props) => {
  const { isAdmin } = useUserContext();
  return (
    <div>
      <div className="flex justify-between py-4 mb-2 border-b border-gray-300 border-solid">
        <h3 className="text-xl font-semibold">Reparatur nicht möglich</h3>
        {isAdmin && (
          <Button className="print-hide" onClick={() => props.handlePrint && props.handlePrint()}>
            Reparatur drucken
          </Button>
        )}
      </div>
      <div className="flex flex-col justify-center mb-4">
        <table className="min-w-full border-2 border-gray-400 border-l-3 border-b-3">
          <thead>
            <tr>
              <TableHead label="Produktart" />
              <TableHead label="Hersteller" />
              <TableHead label="Modell" />
              <TableHead label="Seriennummer" />
              <TableHead label="Schadensbeschreibung" />
              <TableHead label="Schadenskategorien" />
            </tr>
          </thead>
          <tbody className="bg-white">
            {props.repairPieces.map((repairPiece) => (
              <TableRow key={repairPiece.id}>
                <TableCell>
                  <div className="text-gray-900">{repairPiece.productType}</div>
                </TableCell>
                <TableCell>
                  <div className="text-gray-900">{repairPiece.manufacturer}</div>
                </TableCell>
                <TableCell>
                  <div className="text-gray-900">{repairPiece.model}</div>
                </TableCell>
                <TableCell>
                  <div className="text-gray-900">{repairPiece.serialNumber}</div>
                </TableCell>
                <TableCell>
                  <div className="max-w-md text-gray-900">{repairPiece.damageDescription}</div>
                </TableCell>
                <TableCell>
                  <div className="text-gray-900">{repairPiece.damageCategories}</div>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </table>
        {isAdmin && (
          <div className="self-end mt-4">
            <ReturnLabelButtons {...props} />
          </div>
        )}
      </div>
    </div>
  );
};
