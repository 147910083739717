import { Input } from "@/components/tailwind-ui/Input";
import { useMutation } from "@apollo/react-hooks";
import { format, getYear } from "date-fns";
import { useEffect, useState } from "react";
import Button from "../../components/form/Button";
import FileUpload from "../../components/form/FileUpload";
import { useRepairContext } from "../../contexts/RepairContext";
import { CREATE_LABEL, UPDATE_REPAIR } from "../../graphql/queries";
import { padLeft } from "../../helpers/functions";

export const ReturnLabelButtons = (props) => {
  const { user, id, returnLabel, returnTrackingCode, createdAt, repairNumber } = props;
  const { refetchSingle } = useRepairContext();
  const [trackingNumberValue, setTrackingNumberValue] = useState(returnTrackingCode);
  const [createLabel, createLabelResponse] = useMutation(CREATE_LABEL);
  const [updateRepair] = useMutation(UPDATE_REPAIR);

  useEffect(() => {
    setTrackingNumberValue(returnTrackingCode);
  }, [returnTrackingCode]);

  const labelFileName = `UPS Label ${returnTrackingCode} ${format(new Date(), "yyyy-MM-dd")}.pdf`;
  const handleSubmit = async (destination: String) => {
    const year = getYear(new Date(createdAt)) - 2000;
    const data = {
      userId: user.id,
      repairNumber: `R${year}-${padLeft(repairNumber, 4)}`,
      destination,
      withPickup: false,
    };
    try {
      const labelResponse = await createLabel({ variables: { input: data } });
      const returnLabel = labelResponse.data.createUPSLabel.label;
      const returnTrackingCode = labelResponse.data.createUPSLabel.trackingCode;
      const returnDate = new Date().toISOString();
      setTrackingNumberValue(returnTrackingCode);
      await updateRepair({
        variables: {
          id,
          set: { returnLabel, returnTrackingCode, returnDate },
        },
      });
      refetchSingle(id);
    } catch (error) {
      return;
    }
  };

  if (createLabelResponse.loading) {
    return <div className="w-8 h-8 ease-linear border-2 border-t-2 border-gray-200 rounded-full loader"></div>;
  }
  if (returnLabel) {
    return (
      <>
        <div className="mb-4 text-blue-500 underline">
          <a href={returnLabel} download={labelFileName} target="_blank">
            Rücksendelabel downloaden
          </a>
        </div>
        <div>
          <form
            className="space-y-2"
            onSubmit={async (e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              updateRepair({
                variables: {
                  id,
                  set: { returnTrackingCode: formData.get("returnTrackingCode") },
                },
              });
            }}
          >
            <Input
              value={trackingNumberValue}
              name="returnTrackingCode"
              label="Rücksendenummer"
              required
              onChange={(e) => setTrackingNumberValue(e.target.value)}
            />
            <Button type="submit">Rücksende Tracking Nummer aktualisieren</Button>
          </form>
        </div>
      </>
    );
  }

  return (
    <div>
      <Labelupload {...props} />
      <span className="font-semibold">Rücksendelabel erstellen (ohne Abholung)</span>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const submitter = e.nativeEvent["submitter"] as HTMLButtonElement | undefined;
          if (!submitter) return;
          const destination = submitter.getAttribute("data-destination");
          handleSubmit(destination);
        }}
      >
        <div className="-mx-2">
          {user.country === "DE" && (
            <>
              <Button className="w-48 mx-2" type="submit" data-destination="Stehle">
                Von Stehle
              </Button>
              <Button className="w-48 mx-2" type="submit" data-destination="Finetech">
                Von Finetech
              </Button>
              <Button className="w-48 mx-2" type="submit" data-destination="Buxheim">
                Von Buxheim
              </Button>
            </>
          )}
          {user.country === "CH" && (
            <Button className="w-48 mx-2" type="submit" data-destination="Adensys">
              Von Adensys GmbH
            </Button>
          )}
        </div>
        {createLabelResponse.error && (
          <div className="flex justify-end text-red-400">{createLabelResponse.error.message}</div>
        )}
      </form>
    </div>
  );
};

const Labelupload = (props) => {
  const { user, id } = props;
  const { refetchSingle } = useRepairContext();
  const [updateRepair, updateResponse] = useMutation(UPDATE_REPAIR);

  if (updateResponse.loading) {
    return <div className="w-8 h-8 ease-linear border-2 border-t-2 border-gray-200 rounded-full loader"></div>;
  }
  return (
    <div>
      <div className="font-semibold">Label manuell hochladen</div>
      <FileUpload
        fileName={`${user.id}/${id}/UPS Label-${format(new Date(), "yyyy-MM-dd")}`}
        onUpload={async (response) => {
          const returnDate = new Date().toISOString();
          await updateRepair({
            variables: {
              id,
              set: { returnLabel: response.Location, returnDate },
            },
          });
          refetchSingle(id);
        }}
      >
        Hochladen
      </FileUpload>
    </div>
  );
};
