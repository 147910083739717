import { getYear } from "date-fns";
import React from "react";
import Button from "../../components/form/Button";
import { TableCell, TableHead, TableRow } from "../../components/Table";
import { useUserContext } from "../../contexts/UserContext";
import { formatDate, padLeft, sumLineitemsWithDiscount, toPrice } from "../../helpers/functions";
import cn from "classnames";
import { InvoiceUpload } from "../InvoiceUpload";
import { ReturnLabelButtons } from "./ReturnLabelButtons";

export const StaticCostEstimate = (props) => {
  const { isAdmin } = useUserContext();
  const { invoice, user, repairNumber, costEstimates, invoiceURL, status } = props;
  const isFinished = status === "finished";
  const costEstimate = costEstimates[0];
  const { currency, taxRate, approvedAt } = costEstimate;

  const Netto = sumLineitemsWithDiscount(costEstimate.lineItems.filter((lineItem) => lineItem.accepted));

  const taxSum = (Netto / 100) * taxRate;
  const Brutto = Netto + taxSum;
  const year = getYear(new Date(props.createdAt)) - 2000;
  return (
    <div>
      <div className="flex justify-between py-4 mb-2 border-b border-gray-300 border-solid">
        <h3 className="text-xl font-semibold">Auftragsbestätigung</h3>
        {isAdmin && (
          <Button className="print-hide" onClick={() => props.handlePrint && props.handlePrint()}>
            Reparatur drucken
          </Button>
        )}
      </div>
      <div className="flex justify-end max-w-5xl mx-auto mb-4">
        <div>
          <div>
            <span className="font-semibold">Reparaturnummer:</span>
            <div>{`R${year}-${padLeft(repairNumber, 4)}`}</div>
          </div>
          <div>
            <span className="font-semibold">AB-Nummer:</span>
            <div>{isFinished ? invoice.invoiceNumber + 950000 : "N/A"}</div>
          </div>
          <div>
            <span className="font-semibold">Kundennummer:</span>
            <div>{user.customerNumber}</div>
          </div>
          <div>
            <span className="font-semibold">Kunden Telefonnr.:</span>
            <div>{user.phone}</div>
          </div>
          <div>
            <span className="font-semibold">Datum:</span>
            <div>{isFinished ? formatDate(approvedAt) : "N/A"}</div>
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto my-3">
        <div className="inline-block min-w-full align-middle border-2 border-gray-400 border-l-3 border-b-3">
          <table className="min-w-full">
            <thead>
              <tr>
                <TableHead label="Position" />
                <TableHead label="Artikelnummer" />
                <TableHead label="Bezeichnung" />
                <TableHead label="Preis" />
              </tr>
            </thead>
            <tbody className="bg-white">
              {costEstimate.lineItems.map((lineItem, index) => {
                return (
                  <TableRow key={lineItem.id}>
                    <TableCell>
                      <div className={cn({ "line-through": !lineItem.accepted })}>{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      <div className={cn({ "line-through": !lineItem.accepted })}>{lineItem.articleNumber}</div>
                    </TableCell>
                    <TableCell>
                      <div className={cn({ "line-through": !lineItem.accepted })}>
                        <div className="font-medium">{lineItem.headline}</div>
                        {lineItem.description &&
                          lineItem.description.split("\n").map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={cn({ "line-through": !lineItem.accepted })}>
                        {toPrice(lineItem.price, currency)}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={`flex justify-${isAdmin ? "between" : "end"} my-4`}>
          <div className="flex -mx-2">
            <div className="mx-2 text-right">
              <span className="font-semibold">Summe Netto {currency}</span>
              <div>{toPrice(Netto, currency)}</div>
            </div>
            <div className="mx-2 text-right">
              <span className="font-semibold">MwSt {taxRate}%</span>
              <div>{toPrice(taxSum, currency)}</div>
            </div>
            <div className="mx-2 text-right">
              <span className="font-semibold">Summe Brutto {currency}</span>
              <div>{toPrice(Brutto, currency)}</div>
            </div>
          </div>
          {!isAdmin ? (
            <></>
          ) : (
            <div>
              {isFinished && <InvoiceUpload {...props} />}
              <ReturnLabelButtons {...props} />
            </div>
          )}
        </div>
        <div className="py-4 mb-2 border-b border-gray-300 border-solid">
          <p className="text-sm ">
            nach Erhalt der Ware sofort zu zahlen
            <br />
            Rechnungsdatum entspricht Leistungsdatum!
            <br />
            Bitte beachten Sie, dass wir nach Möglichkeit Ersatzteile der ADENSYS GmbH verwenden, <br />
            um wettbewerbsfähige Preise für unsere Reparaturen anbieten zu können.
            <br />
            Sollten Sie ausschließlich OEM-Teile benötigen, teilen Sie uns
            <br /> dies bitte mit, damit wir Ihr Angebot entsprechend anpassen können.
          </p>
        </div>
        <div className="flex justify-between text-xs">
          <div>
            <div>USt.-IdNr.: DE318961218</div>
            <div>St-Nr.: 138/121/22007</div>
            <div>Registergericht / Registry</div>
            <div>Memmingen</div>
            <div>HRB 17846</div>
          </div>
          <div>
            <div>Bankverbindung / Bank account information</div>
            <div>Sparkasse Memmingen</div>
            <div>IBAN: DE87 7315 0000 1001 4899 52</div>
            <div>BIC-SWIFT: BYLADEM1MLM</div>
          </div>
        </div>
      </div>
    </div>
  );
};
