import React, { useState, useEffect } from "react";
import classNames from "classnames";
import useFileUpload from "../../hooks/useFileUpload";

export default function FileUpload(props) {
  const { id, label, className = "", fileName, onUpload } = props;
  const { onChange, isLoading, response, error } = useFileUpload(fileName);
  useEffect(() => {
    if (response.called) {
      onUpload(response);
    }
  }, [response]);
  if (isLoading)
    return <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>;
  return (
    <div className={classNames("mb-4", className)}>
      {label && <label className="block font-semibold my-2">{label}</label>}
      <input className="px-2 py-3 w-full bg-gray-100 border border-gray-400" id={id} onChange={onChange} type="file" />
      {error.message && <p className="text-red-400">{error.message}</p>}
    </div>
  );
}
