import { Input } from "@/components/tailwind-ui/Input";
import { StaticCostEstimate } from "@/components/views/StaticCostEstimate";
import { useRepairFilter } from "@/hooks/useRepairFilter";
import { format, getYear } from "date-fns";
import { NextPage } from "next";
import RepairTable from "../../components/RepairTable";
import { withApollo } from "../../components/WithApollo";
import Button from "../../components/form/Button";
import { IrrepairableView } from "../../components/views/IrrepairableView";
import { useRepairContext } from "../../contexts/RepairContext";
import { useUserContext } from "../../contexts/UserContext";
import { padLeft, sumLineitemsWithDiscount, toPrice } from "../../helpers/functions";
import { useEffect } from "react";

export const InnerProcessingComponent = (props) => {
  if (props.status === "irrepairable") {
    return <IrrepairableView {...props} />;
  }
  return <StaticCostEstimate {...props} />;
};

const transformRepair = (repair) => {
  const year = getYear(new Date(repair.createdAt)) - 2000;

  const costEstimate = repair.costEstimates[0];
  if (!costEstimate) {
    return {
      Reparaturnummer: `R${year}-${padLeft(repair.repairNumber, 4)}`,
      Status: repair.status,
      Kundennummer: repair.user.customerNumber,
      "Anzahl Reparaturstücke": repair.repairPieces.length,
      Gutscheincode: repair.discountCode,
      Reklamation: repair.isReclamation ? "Ja" : "Nein",
      Währung: null,
      "Fester Rabatt": null,
      "Prozentualer Rabatt": null,
      Steuersatz: null,
      "Preis Netto": null,
      MwSt: null,
      "Preis Brutto": null,
      "Erstellt am": format(new Date(repair.createdAt), "dd.MM.yyyy HH:mm"),
    };
  }
  const { currency, taxRate, discountFlat, discountPercent } = costEstimate;
  const Netto = sumLineitemsWithDiscount(costEstimate.lineItems.filter((lineItem) => lineItem.accepted));

  const taxSum = (Netto / 100) * taxRate;
  const Brutto = Netto + taxSum;

  return {
    Reparaturnummer: `R${year}-${padLeft(repair.repairNumber, 4)}`,
    Status: repair.status,
    Kundennummer: repair.user.customerNumber,
    Land: repair.user.country,
    "Anzahl Reparaturstücke": repair.repairPieces.length,
    Gutscheincode: repair.discountCode,
    Reklamation: repair.isReclamation ? "Ja" : "Nein",
    "Gesendet an": repair.sentTo,
    Währung: currency,
    "Fester Rabatt": toPrice(discountFlat, currency),
    "Prozentualer Rabatt": discountPercent,
    Steuersatz: taxRate + "%",
    "Preis Netto": toPrice(Netto, currency),
    MwSt: toPrice(taxSum, currency),
    "Preis Brutto": toPrice(Brutto, currency),
    "Erstellt am": format(new Date(repair.createdAt), "dd.MM.yyyy HH:mm"),
    "Zurückgesendet am": repair.returnDate ? format(new Date(repair.returnDate), "dd.MM.yyyy HH:mm") : "N/A",
    "Genehmigt am": costEstimate.approvedAt ? format(new Date(costEstimate.approvedAt), "dd.MM.yyyy HH:mm") : "N/A",
  };
};

const FinishedRepairs: NextPage = () => {
  const { isAdmin } = useUserContext();
  if (typeof window === "undefined") return null;
  const { archivedLoading: loading, archivedRepairs: repairs, refetchArchived } = useRepairContext();

  useEffect(() => {
    refetchArchived();
  }, []);

  function download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const [filteredRepairs, inputProps] = useRepairFilter(repairs);
  const handleExport = () => {
    const exportRepairs = repairs.map(transformRepair);

    const header = Object.keys(exportRepairs[0]).join(",");
    const data = exportRepairs
      .map((repair) =>
        Object.values(repair).reduce((acc, cur) => {
          cur = cur ? cur : "N/A";
          acc += "," + cur;
          return acc;
        })
      )
      .join("\n");
    download(`Reparaturexport ${format(new Date(), "yyyy-MM-dd")}.csv`, `${header}\n${data}`);
  };

  return (
    <div>
      {isAdmin && (
        <div className="flex items-end justify-between px-6 mx-auto mb-6">
          <div className="space-x-4">
            <Button onClick={handleExport}>Exportieren</Button>
            <Button onClick={() => refetchArchived(true)}>Neu laden</Button>
          </div>
          <div>
            <div className="w-64">
              <Input label="Suche" {...inputProps}></Input>
            </div>
          </div>
        </div>
      )}
      <RepairTable
        repairs={filteredRepairs}
        loading={loading}
        OpenRowComponent={InnerProcessingComponent}
        pageSize={100}
      />
    </div>
  );
};

export default withApollo()(FinishedRepairs);
