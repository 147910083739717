import React, { useState, useRef } from "react";

const defaultResponse = {
  called: false,
  mimeType: undefined as string,
  Bucket: undefined as string,
  ETag: undefined as string,
  Key: undefined as string,
  Location: undefined as string,
  key: undefined as string,
};

const useFileUpload = (fileName: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(new Error());
  const [response, setResponse] = useState(defaultResponse);
  async function upload(file: File): Promise<any> {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file, fileName);
    try {
      const res = await fetch("/api/upload", {
        method: "POST",
        body: formData,
      }).then((res) => res.json());
      setIsLoading(false);
      setResponse({ ...res, called: true });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(error);
    }
  }
  const onChange = (event) => upload(event.target.files[0]);
  return { onChange, isLoading, response, error };
};

export default useFileUpload;
