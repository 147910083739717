import { useMutation } from "@apollo/react-hooks";
import { format } from "date-fns";
import React from "react";
import FileUpload from "../components/form/FileUpload";
import { useRepairContext } from "../contexts/RepairContext";
import { UPDATE_REPAIR } from "../graphql/queries";

export const InvoiceUpload = (props) => {
  const { user, id, invoiceURL } = props;
  if (invoiceURL) return null;
  const { refetchSingle } = useRepairContext();
  const [updateRepair, updateResponse] = useMutation(UPDATE_REPAIR);

  if (updateResponse.loading) {
    return <div className="w-8 h-8 ease-linear border-2 border-t-2 border-gray-200 rounded-full loader"></div>;
  }
  return (
    <div>
      <div className="font-semibold">Rechnung hochladen</div>
      <FileUpload
        fileName={`${user.id}/${id}/Rechnung-${format(new Date(), "yyyy-MM-dd")}`}
        onUpload={async (response) => {
          await updateRepair({
            variables: {
              id,
              set: { invoiceURL: response.Location },
            },
          });
          refetchSingle(id);
        }}
      >
        Hochladen
      </FileUpload>
    </div>
  );
};
